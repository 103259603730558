<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For each of the numbers listed below, determine the number of significant figures.
      </p>

      <p class="mb-0">
        <stemble-latex :content="'a) ' + number1" />
      </p>

      <v-radio-group v-model="inputs.input1" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'b) ' + number2" />
      </p>

      <v-radio-group v-model="inputs.input2" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'c) ' + number3" />
      </p>

      <v-radio-group v-model="inputs.input3" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'d) ' + number4" />
      </p>

      <v-radio-group v-model="inputs.input4" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        <stemble-latex :content="'e) ' + number5" />
      </p>

      <v-radio-group v-model="inputs.input5" row :disabled="!allowEditing" class="mt-1 pl-6">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pr-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'SigFigs2',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options1: [
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '3', value: '3'},
        {text: '4', value: '4'},
        {text: '5', value: '5'},
        {text: '6', value: '6'},
        {text: '7', value: '7'},
        {text: '8', value: '8'},
        {text: '9', value: '9'},
        {text: '10', value: '10'},
      ],
    };
  },
  computed: {
    number1Integer() {
      return this.taskState.getValueBySymbol('arrayVariable1').content;
    },
    number2Integer() {
      return this.taskState.getValueBySymbol('arrayVariable2').content;
    },
    number3Integer() {
      return this.taskState.getValueBySymbol('arrayVariable3').content;
    },
    number4Integer() {
      return this.taskState.getValueBySymbol('arrayVariable4').content;
    },
    number5Integer() {
      return this.taskState.getValueBySymbol('arrayVariable5').content;
    },
    number1() {
      if (this.number1Integer.value === 1) {
        return '$1.23\\times10^{-5}$';
      } else if (this.number1Integer.value === 2) {
        return '$9.1\\times10^{-3}$';
      } else if (this.number1Integer.value === 3) {
        return '$4.21\\times10^{-4}$';
      } else if (this.number1Integer.value === 4) {
        return '$6.531\\times10^{-7}$';
      } else if (this.number1Integer.value === 5) {
        return '$5.8\\times10^{-6}$';
      } else {
        return '$2.867\\times10^{-4}$';
      }
    },
    number2() {
      if (this.number2Integer.value === 1) {
        return '$1.03\\times10^{-5}$';
      } else if (this.number2Integer.value === 2) {
        return '$8.09\\times10^{-3}$';
      } else if (this.number2Integer.value === 3) {
        return '$3.205\\times10^{-4}$';
      } else if (this.number2Integer.value === 4) {
        return '$1.086\\times10^{-2}$';
      } else if (this.number2Integer.value === 5) {
        return '$6.029\\times10^{-6}$';
      } else {
        return '$9.09\\times10^{-6}$';
      }
    },
    number3() {
      if (this.number3Integer.value === 1) {
        return '$4.810\\times10^{-5}$';
      } else if (this.number3Integer.value === 2) {
        return '$3.0\\times10^{-2}$';
      } else if (this.number3Integer.value === 3) {
        return '$6.90\\times10^{-7}$';
      } else if (this.number3Integer.value === 4) {
        return '$5.400\\times10^{-5}$';
      } else {
        return '$2.8000\\times10^{-4}$';
      }
    },
    number4() {
      if (this.number4Integer.value === 1) {
        return '$\\text{pH = }2.43$';
      } else if (this.number4Integer.value === 2) {
        return '$\\text{pH = }8.61$';
      } else if (this.number4Integer.value === 3) {
        return '$\\text{pH = }12.873$';
      } else if (this.number4Integer.value === 4) {
        return '$\\text{pH = }4.694$';
      } else {
        return '$\\text{pH = }13.4$';
      }
    },
    number5() {
      if (this.number5Integer.value === 1) {
        return '$\\text{pOH = }5.430$';
      } else if (this.number5Integer.value === 2) {
        return '$\\text{pOH = }4.0$';
      } else if (this.number5Integer.value === 3) {
        return '$\\text{pOH = }3.80$';
      } else if (this.number5Integer.value === 4) {
        return '$\\text{pOH = }11.20$';
      } else {
        return '$\\text{pOH = }12.400$';
      }
    },
  },
};
</script>
